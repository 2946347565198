<template>
    <div>
        <card-header title="Question" icon="fa-clipboard-list-check"/>
        <card-toolbar>
            <button @click="$openCard('edit-question', {questionId: question.id}, card)"><i class="fas fa-pencil mr-2"/>Edit Question</button>
            <button @click="$openCard('new-answer', {questionId: question.id}, card)"><i class="fas fa-plus mr-2"/>New Answer</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <subheader-list-item title="Question Details" icon="fa-list"/>
                <data-row title="Question">{{ question.question }}</data-row>
                <data-row title="Sub-Text">{{ question.subtext }}</data-row>
                <data-row title="Type">{{ question.type }}</data-row>

                <separator-list-item/>

                <navigation-list-item
                    icon="fa-poll-people"
                    title="View Responses"
                    :badge="question.responses.length"
                    :active="(child && child.definition.component === 'question-responses')"
                    @click="$openCard('question-responses', {questionId: question.id}, card)"
                />

                <subheader-list-item title="Answers" icon="fas fa-comment-check"/>

                <draggable v-model="question.answers" handle=".drag-handle" @end="completeDrag">
                    <transition-group>
                        <answer-list-item
                            :card="card"
                            :answer="a"
                            :key="a.id"
                            :active="(child && child.definition.component === 'edit-answer') && child.props.answerId === a.id.toString()"
                            @click="$openCard('edit-answer', {questionId: question.id, answerId: a.id}, card)"
                            v-for="a in question.answers"
                            class="t-card-list-item"
                        />
                    </transition-group>
                </draggable>
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SearchListItem from "../../TIER/components/SearchListItem";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";
    import NavigationListItem from "../../TIER/components/NavigationListItem";
    import TripListItem from "../../components/TripListItem";

    import draggable from 'vuedraggable';

    import {client as http} from '../../http_client';
    import CardBody from "../../TIER/components/CardBody";
    import DayListItem from "../../components/DayListItem";
    import TripAddOnListItem from "../../components/TripAddOnListItem";
    import QuestionListItem from "@/components/QuestionListItem";
    import DataRow from "@/TIER/components/DataRow";
    import AnswerListItem from "@/components/AnswerListItem";

    export default {
        name: "RegistrationQuestions",
        props: ['card', 'child', 'props'],
        components: {draggable, AnswerListItem, DataRow, QuestionListItem, TripAddOnListItem, CardBody, TripListItem, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                question: {
                    responses: []
                }
            };
        },
        methods: {
            loadQuestion: function(force) {
                this.$emit('loading', true);
                http.get('/api/questions/' + this.props.questionId, {force}).then(response => {
                    this.question = response.data;
                    this.$emit('loading', false);
                });
            },
            setActive: function() {
                // let check = (this.child && this.child.definition.component === 'question') ? parseInt(this.child.props.questionId) : null;
                // this.questions.map(q => q.active = (q.id === check));
            },
            completeDrag: function() {
                http.post('/api/answers/order', this.question.answers.map(p => p.id)).then(response => {

                });
            },
            reload: function() {
                this.loadQuestion(true);
            }
        },
        watch: {
            'props.questionId': function() {
                this.loadQuestion(true);
            },
            'child': function() {
                this.setActive();
            }
        },
        mounted() {
            this.loadQuestion(true);
        }
    };
</script>

<style lang="scss" scoped>

</style>
